import { Controller } from 'stimulus';

export default class extends Controller {

  get video() {
    return this.element.querySelector('video');
  }

  initialize() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        document.body.classList.toggle('scrolled', !entry.isIntersecting);
      });
    });
    this.observer.observe(this.element);
  }

  toggleVideo(e) {
    console.log('toggleVideo');
    e.currentTarget.classList.toggle('is-playing');
    if (this.video.paused) {
      this.video.play();
    } else {
      this.video.pause();
    }
  }
}

